<template>
  <div class="columns is-marginless is-multiline">
    <div class="column is-5-tablet is-4-desktop">
      <div class="columns is-multiline">
        <div class="column is-12">
          <profile-card :user-id="userId" :uploadable="false" />
        </div>
      </div>
    </div>

    <div class="column is-7-tablet is-8-desktop">
      <div class="columns is-multiline">
        <!-- Sites -->
        <div class="column is-12">
          <collapse title="Sites">
            <sites-table
              :user-id="userId"
              :reseller-id="resellerId"
              :allow-add="false"
            />
          </collapse>
        </div>
        <!-- Tasks -->
        <div class="column is-12">
          <collapse title="Tasks">
            <tasks-table :participant-id="userId" :reseller-id="resellerId" />
          </collapse>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ResellerClient",
  components: {
    "profile-card": () => import("@shared/account/_profileCard"),
    "sites-table": () => import("@shared/sites/_sitesTableFE"),
    "tasks-table": () => import("@shared/tasks/_tasksTable")
  },
  data() {
    return {
      userId: this.$route.params.userId
    };
  },
  computed: {
    resellerId() {
      return this.$store.getters["auth/userId"]();
    }
  }
};
</script>
